.root {
  display: flex;
  color: var(--palette-text-primary);
  flex-direction: column;
  width: 100%;
}
.submit {
  cursor: pointer;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin: 0.5rem;
  }
}
