.root {
  display: flex;
  flex-direction: column;
  margin-block: 1rem;
  margin-inline: 0;

  .inputContainer {
    min-width: 250px;
    height: 44px;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: row;

    &:hover,
    &:focus-within {
      box-shadow: var(--shadow-default);
    }

    .adornment {
      padding-block: 0;
      padding-inline: 0.3rem;
      background-color: var(--palette-background-secondary);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }

    .input {
      flex-grow: 1;
      align-self: stretch;
      padding-block: 0;
      padding-inline: 1rem;
      background-color: transparent;
      margin: 0;
      outline: none;
      transition: 0.2s;

      &:focus,
      &:active {
        outline: none;
        border-radius: var(--dimensions-border-radius-default);
        border: solid 2px var(--palette-primary-main);
      }

      &:read-only {
        background-color: var(--palette-background-default);
      }

      &:hover:disabled {
        pointer-events: none;
        cursor: not-allowed;
      }

      &:hover:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }

      &.error {
        border: solid 2px var(--palette-error-main);
      }

      &:user-invalid {
        border: solid 2px var(--palette-error-main);
      }

      &:not(:placeholder-shown) {
        border: solid 1px var(--palette-text-secondary);

        &.error {
          border: solid 2px var(--palette-error-main);
        }

        &:user-invalid {
          border: solid 2px var(--palette-error-main);
        }
      }

      &::placeholder {
        visibility: hidden;
        opacity: 0;
        color: var(--palette-text-secondary);
      }

      &:focus::placeholder {
        visibility: visible;
        opacity: 1;
      }
    }

    .labelText {
      position: absolute;
      inset-block-start: 0.688rem;
      inset-inline-start: 0;
      font-size: 0.938rem;
      line-height: 1.42857;
      padding-block: 0;
      padding-inline: 1px;
      margin-block: 0 0;
      margin-inline: 1rem 0;
      color: var(--palette-text-secondary);
      pointer-events: none;
      transition: 0.2s all ease;
    }

    .input:focus ~ .labelText,
    .input:not(:placeholder-shown) ~ .labelText {
      transform: translate(0, -1.125rem);
      font-size: 0.625rem;
      font-weight: bold;
      color: var(--palette-text-primary);
      background-color: var(--palette-background-secondary);
      text-transform: uppercase;
    }
  }

  .errorMessage {
    font-size: var(--dimensions-font-size-small);
    color: var(--palette-error-main);
    flex: 0 0 auto;
    align-self: flex-start;
    margin-block: 0.25rem;
    margin-inline: 1rem;
  }
}
