@import 'styles/mixins';

.root {
  // Having 100vw doesn't cover 100 % width on mobile sizes
  // width: 100vw;
  width: 100%;
  height: 100vh;
  display: flex;
  backdrop-filter: blur(3px);
  justify-content: center;
  align-items: center;
  background: var(--palette-interaction-light);
  border: none;
  margin: 0;
  color: inherit;
  font-size: inherit;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 49;
  padding: 16px;

  @include sm {
    padding: 32px;
  }
}

.close {
  display: block;
  flex: 0 0 auto;
  position: absolute;
  inset-inline-end: 1rem;
  inset-block-start: 1rem;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  color: var(--error-alert-text-color);
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}
