@import '~styles/mixins';

.root {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  min-height: 100vh;

  @include sm {
    align-items: center;
  }

  .background {
    background-color: var(--palette-background-secondary);
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    z-index: -1;
    @include sm {
      background: var(--palette-background-secondary) url(/assets/dotted-background-image.svg)
        no-repeat bottom center;
    }
  }

  .additionalInfo {
    display: flex;
    flex-direction: column;

    h2 {
      margin-block-start: 0;
    }

    svg,
    img {
      margin-block-start: 2rem;
    }

    .trustpilot {
      margin-block-start: 1rem;
    }

    img {
      align-self: center;
    }
  }

  .narrowContainer {
    @include sm {
      width: 568px;
    }
  }

  .menuWrapper {
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-block: 32px;
    margin-inline: 10px;
    flex: 1;
    @include container-full-width;

    @include sm {
      width: 568px;
      margin-block: 0 32px;
      margin-inline: 32px;
      padding: 0;
    }
    @include lg {
      width: 100%;
      padding-inline-start: 64px;
      padding-inline-end: 64px;
    }
  }
}

.card {
  border-radius: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  width: 100%;

  .innerContainer {
    width: 100%;
    @include sm {
      padding: 40px;
    }
    @include lg {
      padding-block: 40px 40px;
      padding-inline: 56px 40px;

      &:first-child {
        padding-block: 40px 40px;
        padding-inline: 40px 56px;
      }
    }
  }

  .content {
    align-items: center;

    @include sm {
      min-height: 675px;
    }
    @include lg {
      min-height: auto;
    }
  }

  &.withAdditionalData {
    & > div {
      &:first-child {
        display: none;
        border-inline-end: 1px solid var(--palette-divider);
        @include lg {
          display: flex;
        }
      }
    }

    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .content {
        align-items: stretch;
        text-align: start;
      }
    }
  }

  @include sm {
    border-radius: 0.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    & > div {
      width: 25rem;
    }
    background-color: var(--palette-background-secondary);
    & > div {
      width: 30rem;
    }
  }

  @include xl {
    & > div {
      width: 35rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .stepTitle {
      text-transform: uppercase;
      font-size: 1.875rem;
      font-family: var(--font-headings);
      margin-block-start: 0;
      align-self: flex-start;
    }

    .reportProblem {
      align-self: center;
      margin-block-start: 1.5rem;
      color: var(--palette-text-default);

      &:hover {
        color: var(--palette-primary-main);
      }
    }
  }

  h2 {
    font-size: 1.875rem;
    color: var(--palette-primary-dark);
  }
}

.header {
  @include container-full-width;
  margin-block-end: 24px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include sm {
    margin-block-start: 16px;
    flex-direction: column;
  }
  @include md {
    padding-inline-start: 18px;
    padding-inline-end: 18px;
  }
  @include lg {
    flex-direction: row;
  }

  .menu {
    align-self: center;
    text-align: center;
    @include lg {
      align-self: flex-end;
      text-align: end;
    }

    font-family: 'Filson Soft', sans-serif;
    font-weight: 500;

    a {
      text-transform: uppercase;
      font-weight: bold;
      margin-inline-start: 0.5rem;
    }
  }
}
