$screen-sm-min: 48rem; // 768px
$screen-md-min: 64rem; // 1024px
$screen-lg-min: 80rem; // 1280px
$screen-xl-min: 120rem; // 1920px

@mixin under-sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin container-full-width {
  width: 100%;
  max-width: 1408px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: 20px;
  padding-inline-end: 20px;

  @include sm {
    padding-inline-start: 32px;
    padding-inline-end: 32px;
  }
  @include md {
    padding-inline-start: 64px;
    padding-inline-end: 64px;
  }
}
