.visibilityToggle {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 2.75rem;
  height: 100%;
  background: transparent !important;
  color: rgba(1, 42, 75, 0.5);
}

.visibilityToggleIcon {
  height: 1.5rem;
  width: 1.5rem;
}
