@import '~styles/mixins';

.root {
  margin-block: 0;
  margin-inline: auto;
}
.signInWith {
  width: 100%;
  height: 0.75rem;
  border-block-end: 1px solid #b9b9b9;
  text-align: center;
  margin-block-end: 2rem;

  span {
    font-size: 14px;
    background-color: #fff;
    padding-block: 0;
    padding-inline: 0.5rem;
    color: #4d6a81;
  }
}
.socialButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-end: 1rem;
}
.socialButton {
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  text-align: center;
  margin-block: 0;
  margin-inline: 0.3rem;
  height: 38px;
  width: 75px;
  padding: 0;

  a {
    text-indent: -9999px;
    display: block;
    padding: 8px;
  }
}
.facebook a {
  background: url(/assets/facebook.svg) no-repeat center;
}
.google a {
  background: url(/assets/google.svg) no-repeat center;
}
.apple a {
  background: url(/assets/apple.svg) no-repeat center;
}
