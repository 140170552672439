@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

.root {
  color: var(--palette-divider);

  g {
    transform-origin: center;
    animation: linear rotation 5s infinite;
  }

  g:nth-child(odd) {
    animation-direction: reverse;
  }
}
