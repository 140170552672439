@import 'styles/mixins';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;

  .normal {
    background-color: transparent;
  }
  .finish {
    background-color: var(--palette-success-background);
  }
}
.spaced {
  padding-block-start: 16px;
  padding-block-end: 16px;
  @include container-full-width;
  @include sm {
    padding-block-start: 16px;
    padding-block-end: 16px;
  }
  @include md {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
  &.horizontal {
    padding-block-start: 0;
    padding-block-end: 0;
  }
}
